import React, { useState, useEffect } from "react";
import { StickyContainer } from "react-sticky";
import { isScreenLG } from "../../../lib/utils";

const PricingTable = ({ children }) => {
  const [containerClasses, setContainerClasses] = useState("");
  const getContainerClasses = () => {
    if (isScreenLG()) {
      setContainerClasses("container mx-auto");
      return;
    }
    setContainerClasses("max-w-screen items-center overflow-hidden");
  };

  useEffect(() => {
    window.addEventListener("resize", getContainerClasses);

    getContainerClasses();

    return () => {
      window.removeEventListener("resize", getContainerClasses);
    };
  });

  return (
    <div className={containerClasses}>
      <StickyContainer>
        <div className="flex flex-col w-full overflow-auto flex-shrink-0">
          {children}
        </div>
      </StickyContainer>
    </div>
  );
};

export default PricingTable;
