import React, { createContext } from "react";
import { Sticky } from "react-sticky";

export const PricingTableHeaderContext = createContext({});

const PricingTableHeader = ({ children }) => {
  return (
    <Sticky topOffset={-12}>
      {({ style, isSticky }) => (
        <div
          style={{ ...style }}
          className={`z-10 w-full ${isSticky ? "pt-5 bg-cream" : ""}`}
        >
          <PricingTableHeaderContext.Provider value={{ isSticky }}>
            <div className="flex flex-row w-full">{children}</div>
          </PricingTableHeaderContext.Provider>
        </div>
      )}
    </Sticky>
  );
};

PricingTableHeader.propTypes = {};

export default PricingTableHeader;
