import React, { createContext, useState } from "react";
import { isScreenLG } from "../../../lib/utils";

export const PricingTableRowContext = createContext({});

const PricingTableRow = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(isScreenLG());

  return (
    <PricingTableRowContext.Provider
      value={{ isExpanded, setIsExpanded: value => setIsExpanded(value) }}
    >
      <div className="flex flex-row w-full flex-grow">{children}</div>
    </PricingTableRowContext.Provider>
  );
};

export default PricingTableRow;
