import React, { Component } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

import { CallbackOnEnter } from "../../lib/utils";

class Modal extends Component {
  static applyNoScrollToBody(scroll) {
    if (scroll) {
      // eslint-disable-next-line
      const mainElements = document.getElementsByTagName("main");

      if (mainElements.length) {
        mainElements[0].removeEventListener(
          "touchmove",
          Modal.preventTouchScroll,
          false
        );
        mainElements[0].classList.remove("modal-open");
      }
    } else {
      // eslint-disable-next-line
      const mainElements = document.getElementsByTagName("main");

      if (mainElements.length) {
        mainElements[0].classList.add("modal-open");
        mainElements[0].addEventListener(
          "touchmove",
          Modal.preventTouchScroll,
          false
        );
      }
    }
  }

  static preventTouchScroll(e) {
    e.preventDefault();
  }

  constructor(props) {
    super(props);

    this.onBackgroundClick = this.onBackgroundClick.bind(this);
    this.onPressEsc = this.onPressEsc.bind(this);

    this.element = document.createElement("div");
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    this.modalRoot = process.browser
      ? document.getElementById("modal-root")
      : null;

    const { show } = this.props;

    Modal.applyNoScrollToBody(!show);
    if (this.modalRoot && this.element) {
      this.modalRoot.appendChild(this.element);
    }

    document.addEventListener("keydown", this.onPressEsc);
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;
    if (prevProps.show !== show) {
      Modal.applyNoScrollToBody(!show);
    }
  }

  componentWillUnmount() {
    Modal.applyNoScrollToBody(true);
    if (this.modalRoot && this.element) {
      this.modalRoot.removeChild(this.element);
    }

    document.removeEventListener("keydown", this.onPressEsc);
  }

  onPressEsc(event) {
    const { onClose } = this.props;
    if (event.key === "Escape") {
      onClose();
    }
  }

  onBackgroundClick() {
    const { onClose, closeOnBackgroundClick } = this.props;
    if (closeOnBackgroundClick) {
      onClose();
    }
  }

  render() {
    const {
      onClose,
      show,
      children,
      modalClasses,
      modalStyles,
      showCloseIcon,
      defaultPadding,
      headerRow,
      footerRow,
      title,
      fullHeight,
      hideShadowHeader,
      hideShadowFooter,
      customBackgroundColor,
      customFooterBackgroundColor,
      customFooterShaddowBackgroundColor,
      customFooterRowPadding,
      customHeaderTextColor,
      customBackgroundLayoverColor,
      customBackgroundLayoverColorClasses,
      customModal,
      customZIndexClass,
    } = this.props;

    if (!show || !process.browser) {
      return null;
    }

    const modalContent = (
      <div>
        <div
          className={`modal-wrapper ${
            !show ? "hidden" : "flex flex-row"
          } ${customZIndexClass}`}
        >
          <motion.div
            className={`fixed top-0 left-0 ${
              customBackgroundLayoverColor !== ""
                ? ""
                : customBackgroundLayoverColorClasses
            } z-30 h-screen w-screen focus:outline-none`}
            tabIndex={0}
            role="button"
            onClick={this.onBackgroundClick}
            onKeyDown={CallbackOnEnter(this.onBackgroundClick)}
            label="background close button"
            style={{
              backgroundColor: customBackgroundLayoverColor,
            }}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 0.6,
            }}
            transition={{
              duration: 0.1,
            }}
          />
          {customModal || (
            <motion.div
              style={{
                backgroundColor: customBackgroundColor,
                ...modalStyles,
              }}
              className={`modal ${
                !show ? "" : "fadeObjectIn"
              } ${modalClasses} ${fullHeight ? "modal-full-height" : ""}`}
              initial={{ opacity: 0.8, bottom: -40, scale: 0.98 }}
              animate={{
                bottom: 0,
                opacity: 1,
                scale: 1,
              }}
            >
              <div
                className={`flex flex-row flex-shrink-0 justify-between p-5 ${
                  !hideShadowHeader ? "shadow" : ""
                }`}
                style={{ color: customHeaderTextColor }}
              >
                {headerRow || (
                  <div className="inline-flex">
                    <div className="flex flex-row justify-between">
                      <h2
                        className="font-bold leading-none"
                        style={{ color: customHeaderTextColor }}
                      >
                        {title}
                      </h2>
                    </div>
                  </div>
                )}
                {showCloseIcon ? (
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="close-button z-30 ml-2 flex-shrink-0"
                      onClick={onClose}
                      style={{
                        color:
                          customHeaderTextColor !== "#000"
                            ? customHeaderTextColor
                            : "#606f7b",
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} size="lg" />
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col flex-grow relative z-10 overflow-auto scrolling-touch h-full w-full">
                {!hideShadowHeader ? (
                  <div
                    className="pt-5 w-full"
                    style={{
                      backgroundColor: customBackgroundColor,
                    }}
                  />
                ) : null}
                <div
                  className={`flex flex-col flex-shrink-0 flex-grow ${
                    defaultPadding ? "px-2 md:px-5" : "p-0"
                  }`}
                >
                  {children}
                </div>
                {!hideShadowFooter ? (
                  <div
                    className="pt-5 w-full"
                    style={{
                      backgroundColor: !customFooterShaddowBackgroundColor
                        ? customBackgroundColor
                        : customFooterShaddowBackgroundColor,
                    }}
                  />
                ) : null}
              </div>
              {footerRow ? (
                <div
                  className={`flex flex-row flex-shrink-0 ${customFooterRowPadding} ${
                    !hideShadowFooter ? "shadow" : ""
                  }`}
                  style={{ backgroundColor: customFooterBackgroundColor }}
                >
                  {footerRow}
                </div>
              ) : null}
            </motion.div>
          )}
        </div>
      </div>
    );

    return ReactDOM.createPortal(modalContent, this.element);
  }
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  modalClasses: PropTypes.string,
  modalStyles: PropTypes.shape({}),
  closeOnBackgroundClick: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  defaultPadding: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  headerRow: PropTypes.node,
  footerRow: PropTypes.node,
  fullHeight: PropTypes.bool,
  hideShadowHeader: PropTypes.bool,
  hideShadowFooter: PropTypes.bool,
  customBackgroundColor: PropTypes.string,
  customFooterBackgroundColor: PropTypes.string,
  customFooterShaddowBackgroundColor: PropTypes.string,
  customFooterRowPadding: PropTypes.string,
  customHeaderTextColor: PropTypes.string,
  customBackgroundLayoverColor: PropTypes.string,
  customBackgroundLayoverColorClasses: PropTypes.string,
  customModal: PropTypes.node,
  customZIndexClass: PropTypes.string,
};

Modal.defaultProps = {
  modalClasses: "",
  modalStyles: {},
  closeOnBackgroundClick: true,
  showCloseIcon: true,
  defaultPadding: true,
  title: "",
  headerRow: null,
  footerRow: null,
  fullHeight: false,
  hideShadowHeader: false,
  hideShadowFooter: false,
  customBackgroundColor: "#fff",
  customFooterBackgroundColor: "transparent",
  customFooterShaddowBackgroundColor: "",
  customFooterRowPadding: "p-5",
  customHeaderTextColor: "",
  customBackgroundLayoverColor: "",
  customBackgroundLayoverColorClasses: "bg-gray-200 md:bg-white",
  customModal: undefined,
  children: <></>,
  customZIndexClass: "z-50",
};

export default Modal;
