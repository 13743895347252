import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { motion } from "framer-motion";

const animationVariants = {
  visible: {
    opacity: 1,
    height: "100%",
    transitionStart: {
      height: "0%",
      display: "block",
    },
  },
  hidden: {
    opacity: 0,
    height: 0,
    transitionStart: {
      height: "20%",
      opacity: 0.2,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

const ExpandableElement = ({
  title,
  customButton,
  children,
  className,
  open,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div className={`flex flex-col w-full ${className}`}>
      {customButton ? (
        React.cloneElement(customButton, {
          onClick: () => {
            customButton.props.onClick();
            setIsOpen(!isOpen);
          },
        })
      ) : (
        <button
          type="button"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="flex flex-row items-center justify-center text-sm font-bold uppercase p-2"
        >
          <span className="flex-shrink-0">{title}</span>
          <FontAwesomeIcon
            className={`flex-shrink-0 ml-1 transform animate ${
              isOpen ? "rotate-180" : ""
            }`}
            icon={faChevronDown}
          />
        </button>
      )}
      <div className="flex flex-row">
        <div className="flex flex-col w-full">
          <motion.div
            animate={isOpen ? "visible" : "hidden"}
            variants={animationVariants}
            className="w-full overflow-hidden text-lg font-light"
            initial={false}
          >
            {children(isOpen, setIsOpen)}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

ExpandableElement.propTypes = {
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  customButton: PropTypes.node,
  open: PropTypes.bool,
};

ExpandableElement.defaultProps = {
  title: "",
  className: "",
  customButton: null,
  open: false,
};

export default ExpandableElement;
