import React from "react";
import PropTypes from "prop-types";

const PricingColumn = ({
  children,
  first,
  last,
  noPadding,
  customPadding,
  highlighted,
}) => {
  return (
    <div className="flex flex-col w-full sm:w-4/5 lg:w-1/3 px-4 flex-shrink-0">
      <div
        className={`flex flex-col h-full bg-white w-full border-l-2 border-r-2 ${
          highlighted ? "border-yellow-400" : "border-white"
        } ${noPadding || customPadding ? customPadding : "p-4"} ${
          first ? " rounded-t-lg border-t-2" : ""
        } ${last ? " rounded-b-lg border-b-2" : ""}`}
      >
        {children}
      </div>
    </div>
  );
};

PricingColumn.propTypes = {
  first: PropTypes.bool,
  last: PropTypes.bool,
  noPadding: PropTypes.bool,
  customPadding: PropTypes.string,
  highlighted: PropTypes.bool,
};

PricingColumn.defaultProps = {
  first: false,
  last: false,
  noPadding: false,
  customPadding: "",
  highlighted: false,
};

export default PricingColumn;
