import React from "react";

const PricingFeatureList = ({ children }) => {
  return (
    <div className="relative w-full">
      <ol className="list-disc ml-6 mt-4 text-lg feature-list">{children}</ol>
    </div>
  );
};

export default PricingFeatureList;
