import React, { useState } from "react";

import addToMailchimp from "gatsby-plugin-mailchimp";

import PropTypes from "prop-types";
import Modal from "../../modal/Modal";

import { swalSuccess, swalError } from "../../../lib/sweetAlert";

const SubscribeModal = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    const result = await addToMailchimp(email, {
      NAME: name,
    });

    if (result.result !== "error") {
      setIsLoading(false);
      swalSuccess(4000, {
        html: result.msg,
      });
      onClose();
    } else {
      setIsLoading(false);
      swalError({
        title: "Something went wrong",
        html: result.msg,
      });
    }
  };

  return (
    <Modal show onClose={onClose} title="Coming soon!">
      <div className="w-full bg-green-light w-full">
        <div className="text-xl font-bold text-center">
          Hey! Cool that you're here!
          <span role="img" className="pl-1" aria-label="Waving Hand">
            👋
          </span>
        </div>
        <div className="text-base text-center">
          Currently our payed plans are in a closed beta-testing. <br />
          Though, you can sign up here to be one of the first ones to enjoy
          appointer fully. We'll keep you posted!
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="mt-12 w-full flex flex-col items-center"
      >
        <input
          type="text"
          value={name}
          name="name"
          placeholder="Name"
          onChange={e => {
            setName(e.currentTarget.value);
          }}
          className="bg-white focus:outline-none focus:border-blue-500 border-2 border-gray-200 rounded py-2 px-4 block w-full appearance-none leading-normal animate"
        />
        <input
          type="email"
          value={email}
          name="EMAIL"
          placeholder="Email (required)"
          onChange={e => {
            setEmail(e.currentTarget.value);
          }}
          className="bg-white focus:outline-none focus:border-blue-500 border-2 border-gray-200 rounded py-2 px-4 block w-full appearance-none leading-normal animate mt-2"
        />
        <button type="submit" className="mt-6 button" disabled={isLoading}>
          Sign Up
        </button>
      </form>
    </Modal>
  );
};

SubscribeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SubscribeModal;
