import Swal from "sweetalert2/dist/sweetalert2";
import withReactContent from "sweetalert2-react-content";

const swalInstance = withReactContent(Swal);

export default params =>
  swalInstance.fire({
    icon: "error",
    reverseButtons: true,
    buttonsStyling: false,
    ...params,
  });

export const swalSuccess = (timer, params) =>
  swalInstance.fire({
    icon: "success",
    timer: timer || "2000",
    showConfirmButton: false,
    reverseButtons: true,
    buttonsStyling: false,
    ...params,
  });

export const swalError = params =>
  swalInstance.fire({
    icon: "error",
    reverseButtons: true,
    buttonsStyling: false,
    ...params,
  });

export const swalConfirm = params =>
  swalInstance.fire({
    showCloseButton: true,
    buttonsStyling: false,
    icon: "warning",
    ...params,
  });

export const swalInfo = params =>
  swalInstance.fire({
    icon: "info",
    buttonsStyling: false,
    ...params,
  });

export const swalConfirmNegative = params =>
  swalConfirm({
    customClass: {
      confirmButton: "button inverted",
    },
    ...params,
  });
