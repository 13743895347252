import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PricingTableHeaderContext } from "./PricingTableHeader";
import { isScreenLG } from "../../../lib/utils";
import LayoutContext from "../../LayoutContext";
import PricingColumn from "./PricingColumn";

const PricingColumnHeader = ({ title, price, highlighted }) => {
  const { isSticky } = useContext(PricingTableHeaderContext);
  const { isScrollingToTop } = useContext(LayoutContext);
  return (
    <PricingColumn first noPadding highlighted={highlighted}>
      <div
        className={`relative w-full animate ${
          isSticky ? "shadow-lg" : "pt-4 px-4"
        } ${isScreenLG() && isScrollingToTop && isSticky ? "pt-16" : "pt-4"} ${
          highlighted ? "bg-yellow-400" : ""
        }`}
      > 
        <div className="flex flex-row">
          <div className="flex flex-col w-full items-center relative">
            <span className="text-3xl font-bold">{title}</span>
            <span className="text-xl font-light">{price}</span>
          </div>
        </div>
        <div className="divider" />
      </div>
    </PricingColumn>
  );
};

PricingColumnHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  highlighted: PropTypes.bool,
};

PricingColumnHeader.defaultProps = {
  highlighted: false,
};

export default PricingColumnHeader;
