import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import sanitizeHTML from "sanitize-html";

import SEO from "../components/seo";

import ConversionRow from "../components/ConversionRow";

import BusinessIcon from "../images/business.png";
import PricingTable from "../components/pages/pricing/PricingTable";
import PricingTableHeader from "../components/pages/pricing/PricingTableHeader";
import PricingColumnHeader from "../components/pages/pricing/PricingColumnHeader";
import PricingTableRow from "../components/pages/pricing/PricingTableRow";
import PricingColumn from "../components/pages/pricing/PricingColumn";
import PricingFeatureList from "../components/pages/pricing/PricingFeatureList";
import ExpandableFeatureList from "../components/pages/pricing/PricingExpandableFeatureList";
import ExternalLink from "../components/ExternalLink";
import SubscribeModal from "../components/pages/pricing/SubscribeModal";
import FormattedHTMLMessage from "../components/FormattedHTMLMessage";

const Pricing = () => {
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const intl = useIntl();
  return (
    <>
      <SEO title="pricing.seo.title" description="pricing.seo.description" />
      <div className="bg-white relative">
        <div className="container mx-auto">
          <div className="relative flex flex-col-reverse lg:flex-row lg:flex-no-wrap z-20 relative justify-between items-center pt-32 py-24 lg:py-40">
            <div className="flex flex-col w-full items-center">
              <h1 className="text-center leading-tight mb-6">
                <FormattedHTMLMessage id="pricing.headline" />
              </h1>
              <span className="sub-headline">
                <FormattedMessage id="pricing.sub-headline" />
              </span>
            </div>
          </div>
        </div>
        <div className="absolute w-full left-0 bottom-0 pb-4 xl:pb-5">
          <div className="divider" />
        </div>
      </div>
      <div className="bg-cream py-8 mt-24">
        <PricingTable>
          <PricingTableHeader>
            <PricingColumnHeader
              title={intl.formatMessage({ id: "pricing.basic.headline" })}
              price={intl.formatMessage({ id: "pricing.basic.price" })}
            />

            <PricingColumnHeader
              title={intl.formatMessage({ id: "pricing.personal.headline" })}
              highlighted
              price={intl.formatMessage({ id: "pricing.personal.price" })}
            />

            <PricingColumnHeader
              title={intl.formatMessage({ id: "pricing.team.headline" })}
              price={intl.formatMessage({ id: "pricing.team.price" })}
            />
          </PricingTableHeader>

          <PricingTableRow>
            <PricingColumn>
              <PricingFeatureList>
                {/* eslint-disable */}
                <li
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      { id: "pricing.basic.row-1.li-1" },
                      {
                        span: (...chunks) =>
                          `<span class="font-bold mx-1">${chunks.join(
                            ""
                          )}</span>`,
                      }
                    ),
                  }}
                />
                {/* eslint-enable */}
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-1.li-2" />
                </li>
              </PricingFeatureList>
            </PricingColumn>
            <PricingColumn highlighted>
              <PricingFeatureList>
                {/* eslint-disable */}
                <li
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      { id: "pricing.personal.row-1.li-1" },
                      {
                        span: (...chunks) =>
                          `<span class="font-bold mx-1">${chunks.join(
                            ""
                          )}</span>`,
                      }
                    ),
                  }}
                />
                {/* eslint-enable */}
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-1.li-2" />
                </li>
              </PricingFeatureList>
            </PricingColumn>
            <PricingColumn>
              <PricingFeatureList>
                {/* eslint-disable */}
                <li
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      { id: "pricing.team.row-1.li-1" },
                      {
                        span: (...chunks) =>
                          `<span class="font-bold mx-1">${chunks.join(
                            ""
                          )}</span>`,
                      }
                    ),
                  }}
                />
                {/* eslint-enable */}
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-1.li-2" />
                </li>
              </PricingFeatureList>
            </PricingColumn>
          </PricingTableRow>

          <PricingTableRow>
            <PricingColumn>
              <ExpandableFeatureList
                title={intl.formatMessage({
                  id: "pricing.common.row-headlines.row-2",
                })}
              >
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-1" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-2" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-3" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-4" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-5" />
                </li>
              </ExpandableFeatureList>
            </PricingColumn>

            <PricingColumn highlighted>
              <ExpandableFeatureList
                title={intl.formatMessage({
                  id: "pricing.common.row-headlines.row-2",
                })}
              >
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-1" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-2" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-3" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-4" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-5" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-6" />
                </li>
              </ExpandableFeatureList>
            </PricingColumn>
            <PricingColumn>
              <ExpandableFeatureList
                title={intl.formatMessage({
                  id: "pricing.common.row-headlines.row-2",
                })}
              >
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-1" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-2" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-3" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-4" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-5" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-6" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-2.li-7" />
                </li>
              </ExpandableFeatureList>
            </PricingColumn>
          </PricingTableRow>
          <PricingTableRow>
            <PricingColumn>
              <ExpandableFeatureList
                title={intl.formatMessage({
                  id: "pricing.common.row-headlines.row-3",
                })}
              >
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-1" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-2" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-3" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-4" />
                </li>
              </ExpandableFeatureList>
            </PricingColumn>
            <PricingColumn highlighted>
              <ExpandableFeatureList
                title={intl.formatMessage({
                  id: "pricing.common.row-headlines.row-3",
                })}
              >
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-1" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-2" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-3" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-4" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-5" />
                </li>
              </ExpandableFeatureList>
            </PricingColumn>
            <PricingColumn>
              <ExpandableFeatureList
                title={intl.formatMessage({
                  id: "pricing.common.row-headlines.row-3",
                })}
              >
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-1" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-2" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-3" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-4" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-3.li-5" />
                </li>
              </ExpandableFeatureList>
            </PricingColumn>
          </PricingTableRow>
          <PricingTableRow>
            <PricingColumn />
            <PricingColumn highlighted>
              <ExpandableFeatureList
                title={intl.formatMessage({
                  id: "pricing.common.row-headlines.row-4",
                })}
              >
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-4.li-1" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-4.li-2" />
                </li>
              </ExpandableFeatureList>
            </PricingColumn>
            <PricingColumn>
              <ExpandableFeatureList
                title={intl.formatMessage({
                  id: "pricing.common.row-headlines.row-4",
                })}
              >
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-4.li-1" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-4.li-2" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-4.li-3" />
                </li>
              </ExpandableFeatureList>
            </PricingColumn>
          </PricingTableRow>
          <PricingTableRow>
            <PricingColumn />
            <PricingColumn highlighted />
            <PricingColumn>
              <ExpandableFeatureList
                title={intl.formatMessage({
                  id: "pricing.common.row-headlines.row-5",
                })}
              >
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-5.li-1" />
                </li>
                <li>
                  <FormattedMessage id="pricing.common.feature-rows.row-5.li-2" />
                </li>
              </ExpandableFeatureList>
            </PricingColumn>
          </PricingTableRow>
          <PricingTableRow>
            <PricingColumn last>
              <ExternalLink
                to="https://meet.appointer.com"
                id="ga-pricing-basic-button"
                className="button"
              >
                <FormattedMessage id="common.start-now" />
              </ExternalLink>
            </PricingColumn>
            <PricingColumn highlighted last>
              <button
                type="button"
                id="ga-pricing-personal-button"
                className="button"
                onClick={() => {
                  setOpenSubscribeModal(true);
                }}
              >
                <FormattedMessage id="common.start-now" />
              </button>
            </PricingColumn>
            <PricingColumn last>
              <button
                type="button"
                id="ga-pricing-team-button"
                className="button"
                onClick={() => {
                  setOpenSubscribeModal(true);
                }}
              >
                <FormattedMessage id="common.start-now" />
              </button>
            </PricingColumn>
          </PricingTableRow>
        </PricingTable>
        {openSubscribeModal ? (
          <SubscribeModal
            show
            onClose={() => {
              setOpenSubscribeModal(false);
            }}
          />
        ) : (
          ""
        )}
      </div>
      <div className="container mx-auto mt-24">
        <div className="flex flex-col items-center w-full">
          <img src={BusinessIcon} className="w-12" alt="" />
        </div>
        <h2 className="text-center mt-4">
          <FormattedMessage id="pricing.enterprise.headline" />
        </h2>

        <div className="flex flex-row flex-wrap mt-12">
          <div className="flex flex-col w-full md:w-1/2 xl:w-1/3 p-8">
            <span className="text-3xl font-bold text-center md:text-left">
              <FormattedMessage id="pricing.enterprise.claim" />
            </span>
            <a
              id="ga-pricing-enterprise-button"
              href="mailto:b2b@appointer.com"
              className="button mt-4"
            >
              <FormattedMessage id="pricing.enterprise.contact-button" />
            </a>
          </div>
          <div className="flex flex-col w-full md:w-1/2 xl:w-2/3 items-center">
            <div className="bg-cream p-4 w-full rounded-lg p-8 xl:w-2/3">
              <span className="text-3xl font-bold">
                <FormattedMessage id="pricing.enterprise.features.headline" />
              </span>
              <div className="relative">
                <ol className="list-disc ml-6 mt-4 text-xl">
                  <li>
                    <FormattedMessage id="pricing.enterprise.features.li-1" />
                  </li>
                  <li>
                    <FormattedMessage id="pricing.enterprise.features.li-2" />
                  </li>
                  <li>
                    <FormattedMessage id="pricing.enterprise.features.li-3" />
                  </li>
                  <li>
                    <FormattedMessage id="pricing.enterprise.features.li-4" />
                  </li>
                  <li>
                    <FormattedMessage id="pricing.enterprise.features.li-5" />
                  </li>
                  <li>
                    <FormattedMessage id="pricing.enterprise.features.li-6" />
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConversionRow />
    </>
  );
};

export default Pricing;
