import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";

import ExpandableElement from "../../ExpandableElement";

import PricingFeatureList from "./PricingFeatureList";
import { PricingTableRowContext } from "./PricingTableRow";

const ExpandableFeatureList = ({ title, children }) => {
  const { isExpanded, setIsExpanded } = useContext(PricingTableRowContext);

  const customButton = (
    <button
      type="button"
      className="flex flex-row items-center justify-center text-sm font-bold uppercase p-2"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <span className="flex-shrink-0">{title}</span>
      <div className="flex flex-col items-center justify-center flex-shrink-0 h-6 w-6 rounded-full bg-mint-500 mx-1">
        {children.length}
      </div>
      <FontAwesomeIcon
        className={`flex-shrink-0 ml-1 transform animate ${
          isExpanded ? "rotate-180" : ""
        }`}
        icon={faChevronDown}
      />
    </button>
  );

  return (
    <ExpandableElement customButton={customButton} open={isExpanded}>
      {() => {
        return <PricingFeatureList>{children}</PricingFeatureList>;
      }}
    </ExpandableElement>
  );
};

ExpandableFeatureList.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ExpandableFeatureList;
